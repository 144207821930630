import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Search from '../components/search'

const NotFoundPage = () => (
  <Layout title="404: Página NO encontrada">
    <div className="main-content post-main mt-3 p-3">
      <h3>¡Error 404! :)</h3>
      <h1>La página que buscas NO existe</h1>
      <p>Puedes ponerte en <Link to="/contacto">contacto</Link> conmigo y/o utilizar el siguiente buscador:</p>
      <section id="search">
        <Search indices={[{ name: `Blog`, title: `Artículos` }]} />
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
